
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import _ from "lodash";
import BatchCard from "@/components/dashboard/batch-card.vue";
import BatchChart from "@/components/dashboard/batch-chart.vue";
const keys = ["COMMENT", "INTERVENTION", "author"];
@Component({
  components: { BatchCard, BatchChart },
  computed: {},
  async mounted() {
    this.$store.dispatch("loadBatches");
  },
  data: () => ({
    measurements_error: null,
    offset_hours: 0,
    input: {
      COMMENT: null,
      INTERVENTION: null,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    },
    moment,
    show_debug: false,
  }),
  methods: {
    async load() {
      clearInterval(this.$store.state.loader);
      await this.$store.dispatch("loadSelected");
    },
  },
})
export default class ApEasyDens extends Vue {}

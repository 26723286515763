
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { endBatch } from "@/components/devices/helpers";

@Component({
  components: {},
  async mounted() {
    console.log("end batch view");
  },
  props: ["batch", "open"],
  data: () => ({
    rules: {
      REQUIRED: [(value: any) => !!value || "Required."],
      ABV: [(value: any) => !!value || "Required."],
      FG: [(value: any) => !!value || "Required."],
    },
    input: {
      FG: null,
      ABV: null,
      OBSERVED_LEAKS: false,
      OBSERVED_CONDENSATION: false,
      PRESSURE_RELEASED: false,
      ACCURATE_CO2_CONC: true,
      ACCURATE_TOTALIZER: true,
    },
    saving: false,
    valid: true,
    measurements: [],
    error: null,
    moment,
    loading: false,
  }),
  methods: {
    async end_batch() {
      try {
        console.warn("ending", this.$props.batch);
        if (confirm("Are you sure you want to end batch?")) {
          await endBatch(
            {
              id: this.$props.batch.id,
              FG: this.$data.input.FG,
              ABV: this.$data.input.ABV,
              flags: {
                OBSERVED_LEAKS: this.$data.input.OBSERVED_LEAKS,
                OBSERVED_CONDENSATION: this.$data.input.OBSERVED_CONDENSATION,
                PRESSURE_RELEASED: this.$data.input.PRESSURE_RELEASED,
                ACCURATE_CO2_CONC: this.$data.input.ACCURATE_CO2_CONC,
                ACCURATE_TOTALIZER: this.$data.input.ACCURATE_TOTALIZER,
              },
            },
            {
              ...this.$store.getters.tb_api_headers,
            }
          );
          this.$store.dispatch("loadBatches");
        }
        console.warn("ended");
      } catch (error) {
        console.error("failed to end batch", error);
      }
      this.$emit("close", true);
    },
    async cancel() {
      console.log("end batch cancelled", this.$props.id);
      this.$emit("close", true);
    },
  },
})
export default class StartBatch extends Vue {}


import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import EndBatch from "@/components/end-batch.vue";
import Timeline from "@/components/batch/timeline.vue";
@Component({
  components: { EndBatch, Timeline },
  computed: {},
  props: ["batch"],
  mounted() {
    setInterval(() => (this as any).update(), 1000);
  },
  data: () => ({
    updated: "",
    end_batch_dialog: false,
    moment,
  }),
  methods: {
    update() {
      const k = this.$props.batch?.devices.ALICAT?.recent?.find(
        (x: any) => x.mass_flow
      ).ts;
      if (k) this.$data.updated = moment(k).format("YYYY-MM-DD HH:mm:ss");
    },
    async endBatch() {
      this.$data.end_batch_dialog = true;
    },
  },
})
export default class ApEasyDens extends Vue {}
